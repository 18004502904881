.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color:  #9900FF;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.MuiAppBar-colorSecondary {
  color: #fff;
  background-color:  #9900FF !important;
}
.App-link {
  color: #9900FF;
}
 .textcolor {
  color: #fff !important;
  background-color:  #9900FF !important;
}
.deactivebutton{
  color: #000 !important;
  background-color:  #d6d5d2 !important;
}
.textcolorcancel {
  color: #FF375F !important;
  background-color:  #fff !important;
}
.formsimpleform{
  background-color:  #fff;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.RaSidebar-root-24 {
   background: #FFFFFF;
  border: 1px solid rgba(63, 63, 68, 0.005);
  box-shadow: 0px 1px 0px rgb(63 63 68 / 5%), 0px 1px 3px rgb(63 63 68 / 15%);
}
.RaSidebar-drawerPaper-39 {
  /* width: 270px !important; */
  padding: 0px 16px;
}
.MuiMenuItem-root {
  width: auto;
  overflow: hidden;
  font-size: 1rem;
  box-sizing: border-box;
  min-height: 48px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  padding: 12px 16px !important;
  white-space: nowrap;
  letter-spacing: 0.00938em;
}
.MuiListItem-button:hover {
  background: rgba(153, 0, 255, 0.05);
  border-radius: 4px;
}
.MuiListItem-button:hover .MuiSvgIcon-root {
  fill: #9900FF;
}
.RaMenu-main-40 {
  display: flex;
  margin-top: 0.5em;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  margin-bottom: 1em;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 37px !important;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.MuiCheckbox-colorPrimary.Mui-checked {
  color: #9900FF !important;
}
.MuiButton-textPrimary {
  color:#9900FF !important;
}
.MuiButton-containedPrimary {
  color: #fff !important;
  background-color: #9900FF !important;
}
.creator-header-tab{
  display: flex;
  width: 100%;
  height: 38px;
  margin-right: 30px !important;
}
.MuiToolbar-root.MuiToolbar-regular.RaListToolbar-toolbar-59.MuiToolbar-gutters {
  margin-bottom: 40px;
  margin-top: 33px;
  background: #FFFFFF;
  box-shadow: 0px 1px 0px rgb(63 63 68 / 5%), 0px 1px 3px rgb(63 63 68 / 15%);
  border-radius: 4px;
  padding: 16px 24px;
}
.MuiListItem-button:hover {
  text-decoration: none;
  background: rgba(153, 0, 255, 0.05) !important;
}
.RaSidebar-fixed-36 {
  height: calc(100vh - 3em);
  position: fixed;
  background-color: #FFFFFF;
  overflow-x: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
/* .RaMenu-main-40.RaMenu-open-41 {
  padding-top: 24px;
} */
.top-header-tab{
  display: flex;
  border-bottom: 1px solid #DEE2E6;
}
.top-header-tab-title {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #212529;
  padding: 20px;
  cursor: pointer;
}
.top-header-tab-title.active{
  border-bottom: 3px solid #9900FF;
}
.RaMenuItemLink-active-51 .MuiSvgIcon-root {
  fill: #9900FF;
}
span.MuiButton-label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.tbody.MuiTableBody-root.datagrid-body.RaDatagrid-tbody-82 {
 background-color: rgba(153, 0, 255, 0.05) !important;
}
.RaDatagrid-clickableRow-87 {
   cursor: pointer;
 }
 button.MuiButtonBase-root.MuiButton-root.MuiButton-text.RaButton-button-6.textcolor.MuiButton-textPrimary.MuiButton-textSizeSmall.MuiButton-sizeSmall {
  width: 119px;
  border-radius: 100px;
}
.MuiTableCell-root{
  /* width: 217.25px; */
  height: 20px;
 font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-size: 17px !important;
  line-height: 20px;
  letter-spacing: -0.05px;
  color: #212529;
}
.RaLayout-content-4 {
  padding-left: 24px !important;
}
.RaImageInput-dropZone-57 {
  color: rgba(0, 0, 0, 0.87);
  /* cursor: pointer; */
  padding: 8px;
  background: #fff !important;
  text-align: center;
}
.RaImageInput-dropZone-116 {
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
  padding: 8px;
  background: #fff;
  text-align: center;
}
.upload{
color: #9900FF;
margin-left: 20px;
}
.iconbutton{
  height: 20px;
  width: 20px;
  color: #fff;
}
.buttontext{
  color: #fff;
  font-size: 15px;
}
.player-wrapper {
  position: relative;
  padding-top: 20.25% /* Player ratio: 100 / (1280 / 720) */
}
.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
/* universal feed  */
.feed-card{
  border-radius: 20px !important;
  border: 1px solid #E9ECEF;
  margin: auto;
  max-width: 717px;
  width: 100% !important;
}
.post-feed {
  padding: 20px 20px;
  border-radius: 20px 20px 0 0;
  position: relative;
}
.feed-country-day{
padding-left: 10px;
}
.feed-country-day label {
  display: block;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #212529;
}
.feed-country-day span {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #6C757D;
}
.feed-country-day span .dot-days {
  display: inline-block;
  margin: 0px 5px 0 3px;
  background: #6C757D;
  font-size: 0;
  width: 3px;
  height: 3px;
  line-height: 12px;
  border-radius: 50px;
}
.description-p {
  padding-top: 15px;
}
.description-p p{
font-size: 14px;
color: #212529;
font-weight: 400;
line-height: 20px;
margin: 0;
margin-bottom: 10px ;
}
.thought-span{
font-weight: 400;
font-size: 12px;
line-height: 18px;
color: #6C757D;
}
.awssld__bullets{
  bottom: 15px !important;
  z-index: 99;
}
.awssld__bullets button {
  width: 8px !important;
  height: 8px !important;
  background: #FFFFFF !important;
  opacity: 0.5;
  margin: 0 2px !important;
}
.awssld__bullets .awssld__bullets--active {
  transform: inherit !important;
  background: #fff !important;
  opacity: 1;
}
.awssld__bullets button:hover{
  transform: inherit !important;
}
.main-feed-like-comments{
  padding: 15px 20px 20px;
}
.feed-like-save{
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid #DEE2E6;
  margin-bottom: 15px;
}
.comments-avatar img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
  }
  .child-comments{
    padding-left: 10px;
    width: 100%;
  }
  .inputcss{
    width: 100%;
    border: none;
    background: #F8F9FA;
    border-radius: 100px;
    padding: 10px 15px;
    box-sizing: border-box;
    height: 41px;
    outline: none;
  }
  .comment-section-reply{
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #212529;
    margin-bottom: 5px;
  }
  .comment-right-icon-svg{
    color: #6C757D;
    width: 15px !important;
  }
  .inputsearchcss{
    display: flex;
    margin-top: 12px;
    width: 47%;
    border: none;
    background: #fff;
    border-radius: 100px;
    padding: 10px 15px;
    box-sizing: border-box;
    height: 41px;
    outline: none;
    color:  #6C757D;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    /* justify-content: center; */
    align-self: center;
  }
  .deleteContainer{
    height: 32px;
    width: 32px;
    line-height: 45px;
    border-radius: 50px;
    text-align: center;
    border: 2px solid #212529;
    cursor: pointer;
  }

.delete-box{
  padding: 20px 20px;
  box-shadow: 0px 3px 10px rgb(0 0 0 / 10%);
  border-radius: 10px;
  position: absolute;
  z-index: 9;
  background: #fff;
  right: 20px;
  top: 65px;
  width: 300px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 6px;
}

.RaLogin-card-2 {
  min-width: 400px !important;
  margin-top: 0 !important;
  border-radius: 15px !important;
}
.MuiCardActions-root {
  padding: 18px 16px 25px !important;
}
.RaLoginForm-button-7 {
  border-radius: 20px !important;
}
.RaLogin-avatar-3 {
  margin: 20px 16px 0 !important;
}
.MuiCardActions-root {
  padding: 16px 16px 30px;
}
.MuiAvatar-root {
  width: 60px !important;
  height: 60px !important;
}

/* .MuiSvgIcon-root {
  font-size: 2.5rem !important;
} */
/* #root > div {
  background:#F9FAFB !important;
} */
/* .RaLogin-main {
  height: 1px;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: center !important;
  background-repeat: no-repeat;
  background-image: radial-gradient(circle at 50% 14em, #9900FF 0%, #9900FF 60%, #9900FF 100%) !important;
} */
.follower-count-creator tr td, .follower-count-creator tr th {
  text-align: left;
}
a.RaMenuItemLink-active-51 {
  background-color: rgba(153, 0, 255, 0.05) !important;
}
.new-post-image-none .previews {

  display: none;
}
.previews{
  display: flex;
}
.buttonupdate{
  height: 25px;
  width: 100px;
  border-radius: 20px;
  text-align: center;
  border-width: 1;
  background-color:#9900FF;
  align-items: center;
  color: #fff;
  padding-top: 7px;

}

/* .MuiFormHelperText-filled{
  fill: none;
} */